import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "./GlobalVillage.css"
import { Chpt1SubDetail } from '../../../chapterSub/Chpt1Sub/Chpt1SubDetail'

const Global_Village = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>প্রথম অধ্যায়</h1>
            <h3>তথ্য ও যোগাযোগ প্রযুক্তি : বিশ্ব ও বাংলাদেশ প্রেক্ষিত</h3>
            <h2>বিশ্বগ্রাম (Global Village)</h2>
        </div>
        <p className='mt-4'>আমরা যারা শহরে বাস করি অধিকাংশ ক্ষেত্রে পাশের ফ্লাটে বসবাসরত মানুষদের সাথে তেমন যোগাযোগ থাকেনা, এমনকি কিছুক্ষেত্রে আমরা হয়তো জানিওনা পাশের ফ্লাটে আসলে কারা থাকে! তবে গ্রামের মানুষদের মধ্যে বিষয়টা ঠিক বিপরীত। গ্রামের মানুষজন সংঘবদ্ধ, একই গ্রামের সবাই সবাইকে চিনে এবং তারা ভাবের আদান-প্রদান খুব বেশি করে থাকে। যারফলে তাদের মধ্যে সাহায্য-সহযোগিতা ও সম্প্রীতির সম্পর্ক গড়ে ওঠে। </p>
        <p>একে অন্যের সাথে সংযুক্ত থাকার কারনে কোন তথ্য খুব সহজে সবার মাঝে ছড়িয়ে পড়ে এবং সাহায্য-সহযোগিতার মাধ্যমে তারা সুখ-দুঃখ ভাগাভাগি করে।</p>
        <p>ভৌগলিক দূরত্ব থাকা সত্ত্বেও প্রযুক্তি আমাদের সারা বিশ্বের মানুষের মধ্যকার দূরত্ব ক্রমশ কমিয়ে আনছে। দেশ বিদেশের বিভিন্ন মানুষের সাথে আমরা গ্রামের প্রতিবেশীদের মতই নিয়মিত যোগাযোগ শুরু করেছি। ফলে সমস্ত বিশ্ব যেন একীভূত হয়ে সেখানে একটি গ্রামের ন্যায় সৌহার্দ ও ভ্রাতৃত্বের পরিবেশ তৈরী হচ্ছে। </p>
        <h4><b>বিশ্বগ্রাম(Global Village) -এর সংজ্ঞা :</b></h4>
        <p>বিশ্বগ্রাম হচ্ছে এমন একটি ধারণা যেখানে ইলেকট্রনিক মিডিয়া ও তথ্য প্রযুক্তি ব্যবহারের মাধ্যমে পৃথিবীর সকল মানুষ একে অপরের সাথে যোগাযোগ ও সেবা প্রদান করে একটি একক সমাজের ন্যায় বসবাস করবে। </p>
        <h4><b>বিশ্বগ্রামের জনক কে ?</b></h4>
        <p>ক্যানাডিয়ান দার্শনিক মার্শাল ম্যাকলুহান(Marshall Mcluhan) -কে বিশ্বগ্রামের জনক বলা হয়। ১৯৬২ সালে তার প্রকাশিত ‘The Gutenberg Galaxy’ নামক বইয়ে তিনি সর্বপ্রথম বিশ্বগ্রামের ধারণাটি উল্লেখ করেন।</p>
        <h4><b>বিশ্বগ্রাম প্রতিষ্ঠার প্রধান উপাদানসমূহ।</b></h4>
        <ul>
            <li >
            <b>হার্ডওয়্যার(Hardware) :</b> বিশ্বগ্রামে যেকোন যোগাযোগ ও তথ্য আদান-প্রদানের জন্য উপযুক্ত বিভিন্ন ইলেকট্রনিক যন্ত্রপাতি প্রয়োজন। যেমন – কম্পিউটার ও কম্পিউটারের সাথে সংযুক্ত বিভিন্ন যন্ত্রপাতি, মোবাইল,রেডিও, রাউটার, স্যাটেলাইট প্রভৃতি। 
            </li>
            <li >
            <b>সফটওয়্যার(Software) :</b> যোগাযোগ ব্যবস্থায় ব্যবহৃত হার্ডওয়্যারসমূহকে পরিচালনা করার জন্য সফটওয়্যার প্রয়োজন, যেমন – অপারেটিং সিস্টেম, ব্রাউজিং সফটওয়্যার, কমিউনিকেটিং সফটওয়্যার, বিভিন্ন প্রোগ্রামিং ল্যাঙ্গুয়েজ প্রভৃতি। 
            </li>
            <li >
            <b>নেটওয়ার্কের সাথে সংযুক্ততা বা Connectivity :</b> বিশ্বগ্রামের মেরুদন্ড হলো ইন্টারনেট সংযুক্ততা বা কানেক্টিভিটি, যার মাধ্যমে বিশ্বের বিভিন্ন প্রান্তের মানুষ ইন্টারনেটের মাধ্যমে  সার্বক্ষণিকভাবে সংযুক্ত থেকে বিভিন্ন উপাত্ত ও তথ্য নিরাপদভাবে আদান-প্রদান করতে পারবে।
            </li>
            <li >
            <b>ডেটা (Data) :</b> তথ্যের মৌলিক উপাদান হলো ডেটা। এইসব ডেটা প্রক্রিয়াকরণের মাধ্যমেই তৈরি হয় বিশ্বগ্রামের সবচেয়ে মূল্যবান সম্পদ, ‘তথ্য’ বা ‘Information’। 
            </li>
            <li >
            <b> মানুষের সক্ষমতা :</b> বিশ্বগ্রাম তথ্য প্রযুক্তি নির্ভর ব্যবস্থা, সেহেতু এই বিশ্বগ্রাম বাস্তবায়ন করতে হলে তথ্য ও যোগাযোগ প্রযুক্তির অবকাঠামো ব্যবহারের সক্ষমতা থাকা জরুরী।সেই সাথে প্রয়োজন বিভিন্ন প্রযুক্তি ব্যবহারে যথেষ্ট সচেতনতা ও জাগ্রত নৈতিকতাবোধ। 
            </li>
            </ul>
            <h4><b>গ্লোবাল ভিলেজের সুবিধাসমূহ : </b></h4>
            <ul>
                <li>বিশ্বের যে কোনো স্থানের যে কোনো ব্যক্তির সাথে মুহূর্তের মধ্যে যোগাযোগ করা যায় ।</li>
                <li>পৃথিবীব্যাপী তথ্যের ব্যাপক উৎস সৃষ্টি হয়েছে এবং তথ্য পাওয়া সহজলভ্য হয়েছে।</li>
                <li>বিশ্বের বিভিন্ন দেশ এবং তাদের সংস্কৃতি সম্পর্কে জানা যায় ।</li>
                <li>অন-লাইনে যেকোনো লাইব্রেরি থেকে বই পড়া যায় এবং ঘরে বসেই বিশ্বের নামকরা শিক্ষা প্রতিষ্ঠানগুলোর শিক্ষা গ্রহন করা যায়।</li>
                <li>ঘরে বসেই ইন্টারনেটের মাধ্যমে আউটসোর্সিং করে উপার্জন করা যায়। ফলে বিশ্বব্যাপী কর্মসংস্থানের ব্যপক সুযোগ সৃষ্টি হয়েছে।</li>
                <li>টেলিমেডিসিন পদ্ধতিতে পৃথিবীর যেকোনো প্রান্তে বসে বিশ্বের নামকরা চিকিৎসকদের চিকিৎসা সেবা পাওয়া যায়।</li>
                <li>বিভিন্ন অনলাইন প্ল্যাটফর্মে লেখালেখি করার মাধ্যমে কোন বিষয়ে মতামত প্রদান এবং সচেতনতা বৃদ্ধি করা যাচ্ছে।</li>
            </ul>
            <h4><b>গ্লোবাল ভিলেজের অসুবিধাসমূহ : </b></h4>
            <ul>
                <li>হ্যাকিং এর মাধ্যমে তথ্য চুরি এবং তথ্যের গোপনীয়তা নষ্টের সম্ভাবনা থাকে।</li>
                <li>সহজেই মিথ্যা ও বিভ্রান্তিকর তথ্য ছড়িয়ে পড়ার মাধ্যমে বিশৃঙ্খলা সৃষ্টি হতে পারে। </li>
                <li>অনলাইনে বেশি সময় ব্যয় করলে সামাজিক সম্পর্ক ও বন্ধন দূর্বল হয়ে পড়তে পারে। </li>
                <li>সাইবার আক্রমণ ও জালিয়াতি সংঘটিত হওয়ার সম্ভাবনা থাকে।</li>
                <li>খুব সহজে সাংস্কৃতিক বিনিময়ের ফলে কোনো দেশের নিজস্ব সংস্কৃতি রক্ষা কঠিন হয়ে পড়ে। </li>
                <li>অতিরিক্ত প্রযুক্তি ব্যবহারের ফলে শারীরিক জটিলতা সৃষ্টি হতে পারে।</li>
                <li>পৃথিবীর কতিপয় বড় বড় তথ্য প্রযুক্তি কোম্পানি তথ্য নিয়ন্ত্রণ করার ফলে পৃথিবীর ভারসাম্য ক্ষতিগ্রস্থ হওয়ার আশংকা থাকে।</li>
            </ul>
            <h4><b>বিশ্বগ্রামের ধারণা সংশ্লিষ্ট প্রধান উপাদান সমূহ : </b></h4>
            <ul>
                <li>যোগাযোগ</li>
                <li>কর্মসংস্থান</li>
                <li>শিক্ষা</li>
                <li>চিকিৎসা</li>
                <li>গবেষণা</li>
                <li>অফিস</li>
                <li>বাসস্থান</li>
                <li>ব্যবসা বাণিজ্য</li>
                <li>সংবাদমাধ্যম</li>
                <li>বিনোদন ও সামাজিক যোগাযোগ</li>
                <li>সাংস্কৃতিক বিনিময়</li>
            </ul>
                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt1SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default Global_Village