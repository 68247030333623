import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Chpt2SubDetail } from '../../../chapterSub/Chpt2Sub/Chpt2SubDetail'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../../chapter1/GlobalVillage/GlobalVillage.css"

const Communication_system = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>দ্বিতীয় অধ্যায়</h1>
            <h3>কমিউনিকেশন সিস্টেম ও নেটওয়ার্কিং</h3>
            <h3>কমিউনিকেশন সিস্টেম (Communication system)</h3>
        </div>

        <h2 className='dataTransTitle'><b>ডেটা ট্রান্সমিশন মেথড (Data Transmission Method) :</b></h2>

        <p className='mt-4'>ডেটা কমিউনিকেশনে এক ডিভাইস হতে অন্য ডিভাইসে ডেটা বিটের বিন্যাসের মাধ্যমে স্থানান্তরের প্রক্রিয়াকে ডেটা ট্রান্সমিশন মেথড বলে। একে ২ ভাগে ভাগ করা যায়। যথা:-</p>
        <ul>
            <li>সিরিয়াল ডেটা ট্রান্সমিশন।</li>
            <li>প্যারালাল ডেটা ট্রান্সমিশন।</li>
        </ul>
        <h4><b>সিরিয়াল ডেটা ট্রান্সমিশন :</b></h4>

        <p>যে ট্রান্সমিশনে ডেটা বা তথ্য পর্যায়ক্রমে ১ বিট করে আদান প্রদান করে তাকে সিরিয়াল ডেটা ট্রান্সমিশন বলে। ইউএসবি (USB: Universal Serial Bus) পোর্ট একটি সিরিয়াল ট্রান্সমিশনের উদাহরণ।</p>
        <h4><b>বৈশিষ্ট্যসমূহ :</b></h4>
        
        <ul>
            <li>নির্ভরযোগ্য পদ্ধতি। </li>
            <li>যন্ত্রপাতি তুলনামূলকভাবে সহজ এবং সাশ্রয়ী।</li>
            <li>ধীর গতি সম্পন্ন।</li>           
            </ul>
        <h4><b>প্যারালাল ডেটা ট্রান্সমিশন :</b></h4>

        <p>যে ট্রান্সমিশনে ডেটা সমান্তরালভাবে আদান প্রদান হয় তাকে প্যারালাল ডেটা ট্রান্সমিশন বলে। ভিডিও স্ট্রিমিংয়ে,প্যারালাল প্রিন্টার পোর্ট ও ক্যাবল ব্যবহার করে কম্পিউটারের সাথে প্রিন্টারের সংযোগ এর সময় ব্যবহার করা হয়।</p>
        <h4><b>বৈশিষ্ট্যসমূহ :</b></h4>
        
        <ul>
            <li>দ্রুতগতির ট্রান্সমিশন।</li>
            <li>মূল্য সাশ্রয়ী নয়।</li>
            <li>তার স্বাভাবিক বিন্যাস।</li>           
            </ul>
            
            <h4><b>বিট সিনক্রোনাইজেশন : </b></h4>
            <p>সিরিয়াল ডেটা ট্রান্সমিশন পদ্ধতিতে সিগন্যাল পাঠানোর সময় বিভিন্ন বিটের মধ্যে সমন্বয়ের জন্য ব্যবহৃত পদ্ধতিকে বলা হয় বিট সিনক্রোনাইজেশন।</p>
            <p>বিট সিনক্রোনাইজেশনের উপর ভিত্তি করে সিরিয়াল ডেটা ট্রান্সমিশন পদ্ধতিকে তিন ভাগে ভাগ করা যায়। যথা:-</p>
            <ul>
                <li>অ্যাসিনক্রোনাস ট্রান্সমিশন।</li>
                <li>সিনক্রোনাস ট্রান্সমিশন।</li>
                <li>আইসোক্রোনাস ট্রান্সমিশন।</li>
            </ul>

            <h4><b>অ্যাসিনক্রোনাস ট্রান্সমিশন : </b></h4>
            <p>যে ডেটা ট্রান্সমিশন সিস্টেমে প্রেরক হতে ডেটা গ্রাহকে ক্যারেক্টার বাই ক্যারেক্টার ট্রান্সমিট হয় তাকে অ্যাসিনক্রোনাস ট্রান্সমিশন বলে। যেমন: RAM, Cache, or CPU memory ।</p>
            <h4><b>বৈশিষ্ট্যসমূহ : </b></h4>
            <ul>
                <li>যে কোন সময় ডেটা ট্রান্সমিশন করতে পারবে।</li>
                <li>একটি স্টার্ট বিট এবং শেষে একটি অথবা দু’টি   স্টপ বিট পাঠানো হয়।</li>
                <li>কোন প্রাইমারি স্টোরেজ ডিভাইসের প্রয়োজন হয় না।</li>
                <li>মূল্য সাশ্রয়ী।</li>
                <li>দক্ষতা কম।</li>
                <li>স্বল্প গতি সম্পন্ন।</li>
            </ul>
            <h4><b>সিনক্রোনাস ট্রান্সমিশন : </b></h4>
            <p>যে ডেটা ট্রান্সমিশন সিস্টেমে বিরতিহীন ভাবে ডেটা আদান প্রদান কর হয় তাকে সিনক্রোনাস ট্রান্সমিশন বলে। যেমন: মোবাইল ফোন নেটওয়ার্ক, টি.ভি. নেটওয়ার্ক ইত্যাদি।</p>
            <h4><b>বৈশিষ্ট্যসমূহ : </b></h4>
            <ul>
                <li>প্রাথমিক স্টোরেজ ডিভাইসে সংরক্ষণ করা হয়।</li>
                <li>দক্ষতা বেশি।</li>
                <li>সময় কম লাগে।</li>
                <li>ব্যয় বহুল।</li>
                <li>দ্রুত গতি সম্পন্ন।</li>
            </ul>
            <h4><b>আইসোক্রোনাস ট্রান্সমিশন : </b></h4>
            <p>আইসোক্রোনাস শব্দের অর্থ নির্ধারিত সময় পর পর। মূলত অ্যাসিনক্রোনাস ও সিনক্রোনাস এর একটি মিশ্র পদ্ধতি হচ্ছে আইসোক্রোনাস ট্রান্সমিশন। এ প্রক্রিয়ায় অ্যাসিনক্রোনাস পদ্ধতির স্টার্ট ও স্টপ বিটের মাঝখানে সিনক্রোনাস পদ্ধতিতে ব্লক আকারে ডেটা ট্রান্সফার করা হয়। যেমন: অডিও বা ভিডিও কল ইত্যাদি।</p>
            <h2 className='dataTransTitle'><b>ডেটা ট্রান্সমিশন মোড (Data Transmission Mode) :</b></h2>

            <p>ডেটা প্রবাহের দিক-এর উপর নির্ভর করে ডেটা ট্রান্সমিশন মোডকে তিন ভাগে ভাগ করা হয় । যথা:-</p>
            <ul>
                <li>সিমপ্লেক্স মোড।</li>
                <li>হাফ-ডুপ্লেক্স মোড।</li>
                <li>ফুল-ডুপ্লেক্স মোড।</li>
            </ul>
            <h4><b>সিমপ্লেক্স মোড : </b></h4>
            <p>সিমপ্লেক্স ডেটা ট্রান্সমিশন মোডে কেবলমাত্র একদিকে ডেটা প্রেরণের ব্যবস্থা থাকে। যেমন: রেডিও,টিভি ব্রডকাস্ট,কী বোর্ড, মাউস ইত্যাদি।</p>
            <h4><b>হাফ-ডুপ্লেক্স মোড : </b></h4>
            <p>এই পদ্ধতিতে উভয় দিক থেকে ডেটা প্রেরণের বা গ্রহণের সুযোগ থাকে, তবে তা একই সময়ে হওয়া সম্ভব নয়। যেমন: ওয়াকিটকি, ফ্যাক্স, এস.এম.এস।</p>
            <h4><b>ফুল-ডুপ্লেক্স মোড : </b></h4>
            <p>এই পদ্ধতিতে একই সময়ে দুটি ডিভাইস একই সাথে ডেটা প্রেরণ এবং গ্রহণ করতে পারে। যেমন: টেলিফোন,মোবাইল ফোন বা কম্পিউটার নেটওয়ার্ক কমিউনিকেশন।</p>
            <h2 className='dataTransTitle'><b>ডেটা ডিস্ট্রিবিউশন মোড (Data Distribution Mode) :</b></h2>

            <p>প্রাপকের সংখ্যা ও ডেটা গ্রহণের অধিকারের উপর ভিত্তি করে একে চার ভাগে ভাগ করা হয়েছে। যথা :-</p>
            <ul>
                <li>ইউনিকাস্ট।</li>
                <li>ব্রডকাস্ট।</li>
                <li>মাল্টিকাস্ট।</li>
            </ul>
            <h4><b>ইউনিকাস্ট : </b></h4>
            <p>এই ব্যবস্থায় একটি প্রেরকের কাছ থেকে শুধু একটি গ্রাহকই ডেটা গ্রহণ করতে পারবে। সিমপ্লেক্স, হাফ-ডুপ্লেক্স ও ফুল-ডুপ্লেক্স মোডকে ইউনিকাস্ট মোডও বলা হয়। যেমন: মোবাইল, টেলিফোন, ওয়াকিটকি ইত্যাদি।</p>
            <h4><b>ব্রডকাস্ট : </b></h4>
            <p>এ পদ্ধতিতে শুধু একজন প্রেরক থাকলেও ট্রান্সমিশন নেটওয়ার্কের আওতাধীন সব গ্রাহকই ডেটা গ্রহণ করতে পারে। এই পদ্ধতি শুধু সিমপ্লেক্স হয়ে থাকে। যেমন: রেডিও,টেলিভিশন।</p>
            <h4><b>মাল্টিকাস্ট : </b></h4>
            <p>এ পদ্ধতিতে একটি প্রেরক হতে ডেটা প্রেরণ করলেও তা শুধু নির্দিষ্ট সদস্যরা গ্রহণ করতে পারে। যেমন: ভিডিও কনফারেন্সিংয়ে, চ্যাটিং, গ্রুপ ভিডিও চ্যাটিং।</p>
                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt2SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default Communication_system