export var divisionsDistrictInfo={
    "ঢাকা":
        ['নরসিংদী', 'গাজীপুর', 'শরীয়তপুর', 'নারায়ণগঞ্জ', 'টাঙ্গাইল', 'কিশোরগঞ্জ', 'মানিকগঞ্জ', 'ঢাকা', 'মুন্সীগঞ্জ', 'রাজবাড়ী', 'মাদারীপুর', 'গোপালগঞ্জ', 'ফরিদপুর']
     ,
    "বরিশাল":
        ['ঝালকাঠি', 'পটুয়াখালী', 'পিরোজপুর', 'বরিশাল', 'ভোলা', 'বরগুনা']
    , 
    "চট্টগ্রাম":
       ['কুমিল্লা', 'ফেনী', 'ব্রাহ্মণবাড়িয়া', 'রাঙ্গামাটি', 'নোয়াখালী', 'চাঁদপুর', 'লক্ষ্মীপুর', 'চট্টগ্রাম', 'কক্স_বাজার', 'খাগড়াছড়ি', 'বান্দরবান']
    , 
    "খুলনা":
        ['যশোর', 'সাতক্ষীরা', 'মেহেরপুর', 'নড়াইল', 'চুয়াডাঙ্গা', 'কুষ্টিয়া', 'মাগুরা', 'খুলনা', 'বাগেরহাট', 'ঝিনাইদহ']
    , 
    "রাজশাহী":
       ['সিরাজগঞ্জ', 'পাবনা', 'বগুড়া', 'রাজশাহী', 'নাটোর', 'জয়পুরহাট', 'চাঁপাইনবাবগঞ্জ', 'নওগাঁ']
    ,
   "রংপুর":
        ['পঞ্চগড়', 'দিনাজপুর', 'লালমনিরহাট', 'নীলফামারী', 'গাইবান্ধা', 'ঠাকুরগাঁও', 'রংপুর', 'কুড়িগ্রাম']
    , 
    "ময়মনসিংহ":
        ['শেরপুর', 'ময়মনসিংহ', 'জামালপুর', 'নেত্রকোনা']
    , 
    "সিলেট":
       ['সিলেট', 'মৌলভীবাজার', 'হবিগঞ্জ', 'সুনামগঞ্জ']
    }



