import tanvir from "../assets/tanvir.png";
// import user2 from "../assets/user2.jpg";
import ferdous from "../assets/ferdous.png";
import nasim from "../assets/nasim.png";
import rasel from "../assets/rasel.png";
import sakib from "../assets/sakib.png";
import shahin from "../assets/shahin.png";
import { default as asif, default as mahmudullah, default as maruf, default as nayeem, default as rohan } from "../assets/user.jpg";
import yamin from "../assets/yamin.png";

export const ReviewText=[
    {
        title:"প্ল্যাটফর্মের সবচেয়ে বড় সুবিধা হলো এর শেখার নমনীয়তা। প্ল্যাটফর্মটি অত্যন্ত ব্যবহার-বান্ধব এবং নেভিগেট করা খুব সহজ। প্রতিটি লেসন সহজে বুঝতে পারা যায় এবং প্রয়োজন অনুযায়ী পুনরায় দেখা যায়। এই প্ল্যাটফর্মটি তাদের জন্য দারুণ, যারা নিজেদের দক্ষতা বাড়াতে চান এবং সময়ের মধ্যে সীমাবদ্ধতা নেই।",
        name:"তানভীর হোসেন",
        work:"ব্যাচ-১",
        img:tanvir
    },
    {
        title:"এই ই-লার্নিং প্ল্যাটফর্মটি একটি নিরাপদ এবং স্বাচ্ছন্দ্যময় শেখার পরিবেশ প্রদান করে। এর ব্যবহারকারী-মুখী ডিজাইন এবং ইন্টারফেসের কারণে আমি খুব সহজেই বিভিন্ন কোর্সের মধ্যে ঘুরে বেড়াতে পারি। যারা নিজেদের দক্ষতা বাড়ানোর ইচ্ছা পোষণ করেন এবং সময়ের চাপ থেকে মুক্ত থাকতে চান, তাদের জন্য এটি একটি নিখুঁত প্ল্যাটফর্ম।",
        name:"সাকিব আহমেদ",
        work:"ব্যাচ-২",
        img:sakib
    },
    {
        title:"এই প্ল্যাটফর্মের সবচেয়ে গুরুত্বপূর্ণ বৈশিষ্ট্য হলো এর শেখার সময়ের নমনীয়তা। এছাড়াও, লেসনগুলো পুনরায় দেখা যায়, যা আমার শেখার অভিজ্ঞতাকে আরও উন্নত করেছে। যারা নির্দিষ্ট সময়সূচীর মধ্যে আটকে থাকতে চান না এবং নিজেদের দক্ষতা নিজের মতো করে বাড়াতে চান, তাদের জন্য এটি একটি অসাধারণ প্ল্যাটফর্ম।",
        name:"ইয়ামিন আলি",
        work:"ব্যাচ-২",
        img:yamin
    },
    {
        title:"এই ই-লার্নিং প্ল্যাটফর্মটি আমার জন্য একটি মুক্ত শিক্ষার পরিবেশ তৈরি করেছে। আমি যখন খুশি, তখনই লেসনগুলো দেখতে পারি এবং প্রয়োজন অনুযায়ী আবারও ফিরে যেতে পারি। এর ইন্টারফেস অত্যন্ত ব্যবহারবান্ধব এবং এটি আমাকে নিজের শেখার প্রক্রিয়াকে সম্পূর্ণ স্বাধীনভাবে পরিচালনা করতে দেয়।",
        name:"শাহারিয়ার রাসেল",
        work:"ব্যাচ-৩",
        img:rasel
    },
    {
        title:"এই প্ল্যাটফর্মের সবচেয়ে ভালো দিক হলো, আমি আমার শেখার গতি এবং সময়সূচী পুরোপুরি নিজের নিয়ন্ত্রণে রাখতে পারি। যেকোনো সময় লেসনগুলো দেখতে পারি এবং প্রয়োজন হলে পুনরায় দেখার সুযোগও রয়েছে। এর ব্যবহারকারী-মুখী ইন্টারফেস এবং সহজ নেভিগেশন আমাকে শেখার সময় স্বাচ্ছন্দ্য দেয়।",
        name:"শাহিনুর খান",
        work:"ব্যাচ-১",
        img:shahin
    },
    {
        title:"আমার দক্ষতা বাড়ানোর জন্য একটি নমনীয় উপায়ের প্রয়োজন ছিল এবং এই প্ল্যাটফর্মটি ছিল নিখুঁত সমাধান। বাস্তব জগতের কেস স্টাডি এবং ইন্টারেক্টিভ উপাদানগুলো শেখাকে উপভোগ্য এবং বাস্তবসম্মত করেছে। তাদের গ্রাহক সেবা সত্যিই প্রশংসনীয় এবং এটি প্ল্যাটফর্মটির প্রতি আমার বিশ্বাস আরও দৃঢ় করেছে।",
        name:"নাসিম উদ্দিন",
        work:"ব্যাচ-৩",
        img:nasim
    },
   
    
    
   
    {
        title:"এই প্ল্যাটফর্মটি আমার শেখার অভিজ্ঞতাকে এক নতুন উচ্চতায় নিয়ে গেছে। আমি যখনই সময় পাই, তখনই কোর্সগুলো করতে পারি এবং প্রতিটি লেসন এমনভাবে সাজানো যে তা সহজেই বুঝতে পারা যায়। যারা কাজের চাপে থেকেও নিজেদের শিক্ষাকে অগ্রসর করতে চান, তাদের জন্য এটি একটি আদর্শ মাধ্যম।",
      name:"ইউজার-১",
      work:"ব্যাচ-২",
      img:mahmudullah
  },
    {
        title:"আমার দক্ষতা বাড়ানোর জন্য একটি নমনীয় উপায়ের প্রয়োজন ছিল এবং এই প্ল্যাটফর্মটি ছিল নিখুঁত সমাধান। যে প্রকল্প ব্যবস্থাপনা কোর্সটি আমি নিয়েছিলাম তা ব্যাপক এবং সর্বশেষ শিল্প চর্চার সাথে আপ-টু-ডেট ছিল। বাস্তব জগতের কেস স্টাডি এবং ইন্টারেক্টিভ উপাদানগুলো শেখাকে উপভোগ্য এবং বাস্তবসম্মত করেছে।",
        name:"ইউজার-২",
        work:"ব্যাচ-৩",
        img:rohan

    },
    {
        title:"এই প্ল্যাটফর্মটি আমার শেখার অভিজ্ঞতাকে এক নতুন উচ্চতায় নিয়ে গেছে। আমি যখনই সময় পাই, তখনই কোর্সগুলো করতে পারি এবং প্রতিটি লেসন এমনভাবে সাজানো যে তা সহজেই বুঝতে পারা যায়। যারা কাজের চাপে থেকেও নিজেদের শিক্ষাকে অগ্রসর করতে চান, তাদের জন্য এটি একটি আদর্শ মাধ্যম।",
        name:"ইউজার-৩",
        work:"ব্যাচ-২",
        img:maruf
    },
    {
        title:"এই প্ল্যাটফর্মটি আমার শেখার অভিজ্ঞতাকে এক নতুন উচ্চতায় নিয়ে গেছে। আমি যখনই সময় পাই, তখনই কোর্সগুলো করতে পারি এবং প্রতিটি লেসন এমনভাবে সাজানো যে তা সহজেই বুঝতে পারা যায়। যারা কাজের চাপে থেকেও নিজেদের শিক্ষাকে অগ্রসর করতে চান, তাদের জন্য এটি একটি আদর্শ মাধ্যম।",
        name:"ইউজার-৪",
        work:"ব্যাচ-২",
        img:nayeem
    },{
        title:"আমার দক্ষতা বাড়ানোর জন্য একটি নমনীয় উপায়ের প্রয়োজন ছিল এবং এই প্ল্যাটফর্মটি ছিল নিখুঁত সমাধান। যে প্রকল্প ব্যবস্থাপনা কোর্সটি আমি নিয়েছিলাম তা ব্যাপক এবং সর্বশেষ শিল্প চর্চার সাথে আপ-টু-ডেট ছিল। বাস্তব জগতের কেস স্টাডি এবং ইন্টারেক্টিভ উপাদানগুলো শেখাকে উপভোগ্য এবং বাস্তবসম্মত করেছে।",
        name:"ইউজার-৫",
        work:"ব্যাচ-৩",
        img:asif

    },{
        title:"এই প্ল্যাটফর্মটি আমার শেখার অভিজ্ঞতাকে এক নতুন উচ্চতায় নিয়ে গেছে। আমি যখনই সময় পাই, তখনই কোর্সগুলো করতে পারি এবং প্রতিটি লেসন এমনভাবে সাজানো যে তা সহজেই বুঝতে পারা যায়। যারা কাজের চাপে থেকেও নিজেদের শিক্ষাকে অগ্রসর করতে চান, তাদের জন্য এটি একটি আদর্শ মাধ্যম।",
      name:"ফেরদৌস",
      work:"ব্যাচ-২",
      img:ferdous
  },
      
]