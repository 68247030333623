export const faqMenu=[
   
    {
        title:"মোবাইল ডিভাইস থেকে কী কোর্স এক্সেস করতে পারবো?",
        desc:" হ্যাঁ, আমাদের প্ল্যাটফর্ম মোবাইল এবং ট্যাবলেট সহ সকল ডিভাইসে সমর্থিত। আপনি আমাদের ওয়েবসাইট ব্যাবহার করে যেকোনো সময়, যেকোনো স্থান থেকে কোর্স অ্যাক্সেস করতে পারবেন।",
        flushID:"flush-headingThree",
        flushCollaps:"flush-collapseThree",
        flushCollapsTarget:"#flush-collapseThree"
    },
    {
        title:"কোর্সে সফলতা অর্জনের জন্য কোনো টিপস আছে কি?",
        desc:"নিয়মিত অংশগ্রহণ করুন:ক্লাস ও অ্যাসাইনমেন্টে সক্রিয়ভাবে অংশ নিন । নোট নিন: ক্লাস চলাকালীন নোট গ্রহণ করুন । ক্লাসের বাইরে অনুশীলন করুন: শেখা বিষয়গুলি বাস্তবে প্রয়োগের চেষ্টা করুন।প্রশ্ন করুন: কিছু বুঝতে অসুবিধা হলে, শিক্ষকের কাছে প্রশ্ন করতে দ্বিধা করবেন না।",
        flushID:"flush-headingFour",
        flushCollaps:"flush-collapseFour",
        flushCollapsTarget:"#flush-collapseFour"
    },
    {
        title:"কোর্স শেষ করতে যদি সমস্যা হয়, তাহলে কী করা উচিত?",
        desc:"যদি কোর্স শেষ করতে কোনো সমস্যায় পড়েন, আপনি আমাদের সহায়ক দলের সাথে যোগাযোগ করতে পারেন। এছাড়াও, ফোরামে অন্যান্য শিক্ষার্থীদের সাথে আপনার সমস্যাগুলো আলোচনা করতে পারেন। আমাদের শিক্ষকরাও আপনার জন্য সহায়ক হতে পারেন।",
        flushID:"flush-headingFive",
        flushCollaps:"flush-collapseFive",
        flushCollapsTarget:"#flush-collapseFive"
    },
    {
        title:"কোর্সের সময় অনলাইন পরীক্ষার জন্য প্রস্তুতি কিভাবে নিব?",
        desc:"  অনলাইন পরীক্ষার জন্য প্রস্তুতি নেওয়ার জন্যপ্রতিটি মডিউল মনোযোগ দিয়ে পড়ুন।প্র্যাকটিস কুইজগুলো সমাধান করুন।ইনস্ট্রাক্টরের সাথে আপনার প্রশ্নগুলি আলোচনা করুন।পূর্ববর্তী প্রশ্নপত্র এবং নমুনা প্রশ্নপত্র দেখুন।",
        flushID:"flush-headingSix",
        flushCollaps:"flush-collapseSix",
        flushCollapsTarget:"#flush-collapseSix"
    },
    {
        title:"কিভাবে আপনি ক্যারিয়ার গাইডেন্স পেতে পারেন?",
        desc:"আমরা আমাদের শিক্ষার্থীদের জন্য ক্যারিয়ার গাইডেন্স এবং পরামর্শ প্রদান করি। আপনি আমাদের ক্যারিয়ার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন অথবা লাইভ ওয়েবিনারে অংশগ্রহণ করতে পারেন।",
        flushID:"flush-headingSeven",
        flushCollaps:"flush-collapseSeven",
        flushCollapsTarget:"#flush-collapseSeven"
    },
    {
        title:"এগুলোর বাইরেও আমার অন্য প্রশ্ন আছে, সেটা কিভাবে করব?",
        desc:" আমরা আমাদের শিক্ষার্থীদের জন্য ক্যারিয়ার গাইডেন্স এবং পরামর্শ প্রদান করি। আপনি আমাদের ক্যারিয়ার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন অথবা লাইভ ওয়েবিনারে অংশগ্রহণ করতে পারেন।",
        flushID:"flush-headingEight",
        flushCollaps:"flush-collapseEight",
        flushCollapsTarget:"#flush-collapseEight"
    }
    
]