import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Chpt2SubDetail } from '../../../chapterSub/Chpt2Sub/Chpt2SubDetail'
import Footer from '../../../Footer/Footer'
import Nav from '../../../Nav/Nav'
import Newsletter from '../../../newsletter/Newsletter'
import TopBar from '../../../topbar/TopBar'
import "../../chapter1/GlobalVillage/GlobalVillage.css"

const Communication_Method = () => {
  return (
        <div>
            <TopBar/>
            <Nav/>
        <div className="container chapterOneMain">
            <div className="row chapterOneMain py-3">
                <div className="col-lg-9">
                <div className="booktop">
            <h1>দ্বিতীয় অধ্যায়</h1>
            <h3>কমিউনিকেশন সিস্টেম ও নেটওয়ার্কিং</h3>
            <h3>ডাটা কমিউনিকেশন মাধ্যম (Data Communication Method)</h3>
        </div>

        <p className='mt-4'>ডেটা আদান-প্রদানের ক্ষেত্রে প্রেরক থেকে গ্রাহক পর্যন্ত যে সব সংযোগ স্থাপন করা হয় তাদেরকে ডেটা কমিউনিকেশন মাধ্যম বলা হয়।</p>
        
        <h2 className='dataTransTitle'><b>তার মাধ্যম :</b></h2>

        <p>নির্দিষ্ট কোনো পথে বৈদ্যুতিক সংকেত পাঠনোর জন্য মাধ্যম হিসেবে কপার বা অ্যালুমিনিয়ামের তার বা ক্যাবল ব্যবহার করে ডেটা কমিউনিকেশনের ব্যবস্থা করা হয়। </p>
        <h4><b>টুইস্টেড পেয়ার ক্যাবল :</b></h4>
        <p>দুটি পরিবাহী তারকে পরস্পর সুষমভাবে পেঁচিয়ে টুইস্টেড পেয়ার ক্যাবল তৈরি করা হয়। </p>
        <h4><b>বৈশিষ্ট্যসমূহ :</b></h4>
        
        <ul>
            <li>100 মিটারের বেশি দূরত্বে কোনো ডেটা প্রেরণ করা যায় না। </li>
            <li>এর ব্যান্ডউইথ 10 Mbps থেকে 1 Gbps পর্যন্ত হতে পারে।</li>
            <li>এ ক্যাবলে সাধারণত ৪ জোড়া তার একসাথে থাকে।</li>           
            <li>প্রতি জোড়ায় একটি কমন সাদা রঙের তার এবং বাকি তার গুলো ভিন্ন রঙের থাকে।</li>           
            </ul>
        <h4><b>টুইস্টেড পেয়ার ক্যাবলকে ২ ভাগে বিভক্ত করা যায়। যথা:- </b></h4>
        <ul>
            <li>আনশিল্ডেড টুইস্টেড পেয়ার ক্যাবল। </li>
            <li>শিল্ডেড টুইস্টেড পেয়ার ক্যাবল।</li>          
            </ul>
            <h4><b>আনশিল্ডেড টুইস্টেড পেয়ার ক্যাবল :</b></h4>
        <p>ব্যান্ডউইডথ সাধারণ ১ থেকে ১৫৫ Mbps পর্যন্ত হয়ে থাকে।</p>
            <h4><b>শিল্ডেড টুইস্টেড পেয়ার ক্যাবল :</b></h4>
        <p>শিন্ড সাধারণত অ্যালুমিনিয়াম বা পলিয়েস্টারের হয়ে থাকে। প্রতিটি শিল্ডেড জোড়া তার আবার প্লাস্টিক জ্যাকেটের মাঝে থাকে। ব্যান্ডউইডথ সাধারণত ১৬ Mbps থেকে ৫০০ Mbps পর্যন্ত হতে পারে। </p>
            <h4><b>কো-এক্সিয়াল ক্যাবল :</b></h4>
        <p>এটি একটি তামা বা কপার নির্মিত তিন স্তর বিশিষ্ট তারের ক্যাবল। কেন্দ্রস্থলে একটি শক্ত তামার তারের কন্ডাক্টর, সেটিকে বৃত্তাকারে ঘিরে প্লাস্টিকের অপরিবাহী স্বর এবং এই স্তরকে ঘিরে তামার তারের একটি জাল বা শিল্ড। সবশেষে রাবারের অপরিবাহী পুরু স্তর এই ক্যাবলটিকে আবৃত করে রাখে।</p>
        <h4><b>বৈশিষ্ট্যসমূহ :</b></h4>
        
        <ul>
            <li>দু’টি বিদ্যুৎ পরিবাহী তার ও দুটি অপরিবাহী পদার্থের সাহায্যে এ ক্যাবল তৈরি করা হয়।</li>
            <li>ডেটা ট্রান্সফার রেট অনেক বেশি হয়ে থাকে।</li>
            <li>ডেটা ট্রান্সমিশন লস্ অপেক্ষাকৃত কম।</li>           
            <li>সহজে বাস্তবায়নযোগ্য।</li>           
            </ul>
            
            <h4><b>কো-এক্সিয়াল ক্যাবল দুধরনের হয়ে থাকে। যথা:- </b></h4>
        <ul>
            <li>থিননেট। </li>
            <li>থিকনেট।</li>          
            </ul>
            <h4><b>থিননেট : </b></h4>
            <p>এটি চিপারনেট থিন ইথারনেট বা থিনওয়‍্যার নামেও পরিচিত। তবে এটি 10BASE2 ইথারনেট নামেই বেশি পরিচিত। কার্যকরভাবে ১৮৫ মিটার পর্যন্ত কাজ করে। 10Mbps গতিতে ডেটা আদান প্রদান করে।</p>
            <h4><b>থিকনেট : </b></h4>
            <p>থিকনেট ভারী ও নন-ফ্লেক্সিবল ক্যাবল। এই তার 10BASE-5 নামেও পরিচিত। এ ক্যাবলটি দ্বারা সর্বোচ্চ 500 মিটার দূরত্বে 10mbs স্পিডে ডেটা সহজেই আদান-প্রদান করা যায়।</p>
            <h4><b>ফাইবার অপটিক ক্যাবল : </b></h4>
            <p>অপটিক্যাল ফাইবার ক্যাবল হল এক ধরনের আলো পরিবাহী তার। এটি কাচ বা প্লাস্টিক বা অন্য কোনো স্বচ্ছ মাধ্যম দিয়ে তৈরি। কাঁচের মধ্য দিয়ে আলোক সংকেতরূপে পূর্ণ অভ্যন্তরীণ প্রতিফলন পদ্ধতিতে ডেটা প্রবাহিত হয়। এর ৩টি অংশ আছে। যথা:-</p>
           
            <ul>
                <li>কোর।</li>
                <li>ক্ল‍্যাড।</li>
                <li>জ্যাকেট।</li>
            </ul>
            <p><b>কোর :</b> যে অংশের প্রতিসরাংক বেশি তাকে কোর বলে। এটি ভিতরের অংশ।</p>
            <p><b>ক্ল‍্যাড :</b>  যে অংশের প্রতিসরাংক কম তাকে ক্ল‍্যাড বলে। এটি মাঝের অংশ।</p>
            <p><b>জ্যাকেট :</b>  আবরণ হিসেবে কাজ করে। </p>
            <p>কোর এর ব্যাস অনুযায়ী একে ২ ভাগে ভাগ করা যায়  যথা:-</p>
            <h4><b>সিঙ্গেল মোড ও এর বৈশিষ্টসমূহ : </b></h4>
            
            <ul>
                <li>প্রযুক্তি তুলনামূলকভাবে ব্যয়সাধ্য।</li>
                <li>দীর্ঘ দূরত্বে হাই স্পিড সম্পন্ন একটি মাধ্যম।</li>
                <li>প্রযুক্তি তুলনামূলকভাবে ব্যয়সাধ্য।</li>
            </ul>
            <h4><b>মাল্টিমোড ও এর বৈশিষ্ট্যসমূহ : </b></h4>
            
            <ul>
                <li>কোরের ব্যাস 50 থেকে 100 মাইক্রন হয়।</li>
                <li>স্বল্প দূরত্বে কম স্পিডের কাজে ব্যবহার হয়। </li>
                <li>প্রযুক্তি তুলনামূলকভাবে সহজ এবং মূল্য সাশ্রয়ী।</li>
            </ul>
            <h4><b>লেজার : </b></h4>
            <p>এলইডি এর আলোতে তরঙ্গ দৈর্ঘ্য সুনির্দিষ্ট হয় না তাই ফাইবারের ভেতর দিয়ে যাওয়ার সময় সিগন্যালের বিচ্যুতি ঘটে, সেজন্য এটি দীর্ঘ দূরত্বে ব্যবহার করা যায় না। লেজারের তরঙ্গ দৈর্ঘ্য সুনির্দিষ্ট বলে এটি দূরের কমিউনিকেশনে ব্যবহার করা যায়। এর তরঙ্গ দৈর্ঘ্য 1300 nm থেকে 1500 nm। </p>
        <h2 className='dataTransTitle'><b>তার বিহীন মাধ্যম :</b></h2>
        <p>কোনো ধরনের মাধ্যম ছাড়া যখন তথ্য আদান-প্রদান করা হয় তখন তাকে তারবিহীন বা ওয়্যারলেস মিডিয়া বলে।</p>
        <h4><b>রেডিও ওয়েভ ও এর বৈশিষ্ট্যসমূহ : </b></h4>
            
            <ul>
                <li>কম্পাংক 3 কিলোহার্টজ থেকে 300 গিগাহার্টজ হলেও অধিক কার্যকর 10 কিলোহার্টজ থেকে 1 গিগাহার্টজ এর মধ্যে।</li>
                <li>তরঙ্গ দৈর্ঘ্য 30km থেকে 30 cm পর্যন্ত কাজ করে।</li>
                <li>একই ফ্রিকুয়েন্সিতে একাধিক প্রেরক থাকলে তাদের মধ্যে ইন্টারফিয়ারেন্স হয়।</li>
                <li>বেশি ডেটা ট্রান্সমিট করতে পারে না।</li>
                <li>প্রতিকূল পরিবেশে রেডিও যোগাযোগ কার্যকর থাকে।</li>
                <li>ডেটা চারদিকে ছড়িয়ে পড়ে।</li>
            </ul>
        <h4><b>মাইক্রোওয়েভ ও এর বৈশিষ্ট্যসমূহ : </b></h4>
            
            <ul>
                <li>1 GHz থেকে 100 GHz কম্পাংক সম্পন্ন। 2GHz বা এর অধিক কম্পাঙ্ক এ ডেটা ট্রান্সমিট করে।</li>
                <li>এর তরঙ্গ সোজাসুজি যায়।</li>
            </ul>
        <h4><b>মাইক্রোওয়েতে যোগাযোগ দুধরনের হয়ে থাকে : </b></h4>
            
            <ul>
                <li>টেরিস্ট্রিয়াল মাইক্রোওয়েভ।</li>
                <li>স্যাটেলাইট মাইক্রোওয়েভ।</li>
            </ul>
            <h4><b>টেরিস্ট্রিয়াল মাইক্রোওয়েভ : </b></h4>
            <p>সাধারণত যে সব জায়গায় ক্যাবল ব্যবহার করার অনুপযোগী সে সব স্থানে টেরিস্ট্রিয়াল ট্রান্সমিটার বসানো হয়। মধ্যবর্তী কোন বাঁধা অতিক্রম করতে পারে না। এজন্যে এদের কে উচু জায়গায় রাখা হয়। প্রতি ৪০ থেকে ৫০ কিলোমিটার পরপর রিপিটার বা রিলে স্টেশন বসাতে হয়।</p>
            <h4><b>স্যাটেলাইট মাইক্রোওয়েভ : </b></h4>
            <p>একটি স্যাটেলাইট ভূপৃষ্ঠ থেকে ৩৪০০০ কিমি ঊর্ধ্বাকাশে স্থাপিত করা হলে সেটি জিওস্টেশনারি হয়। মাইক্রোওয়েভ বায়ুমন্ডলের আয়নোস্ফিয়ার ভেদ করে যাতায়াত করতে পারে বলে কৃত্রিম উপগ্রহের মাধ্যমে মাইক্রোওয়েভে সিগন্যাল আদান-প্রদান করা হয়। সিগন্যাল গ্রহণ করার জন্যে ভূমিতে VSAT বসানো হয়। বঙ্গবন্ধু-১ এট Geostationary Earth Orbit বা GEO স্যাটেলাইট।</p>

                </div>
                <div className="col-lg-3 ">
                <div className="chapterLeft">
                {
      Chpt2SubDetail.map((item,index)=>{
        return(
          <Link key={index} to={item.name} className="mb-4">
             <h4 className='chapterOne'>{item.title}</h4>
          </Link>
        )
      })
    }
                </div>
                </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
        </div>
  )
}

export default Communication_Method